import React from 'react';
import '../scss/VideoArticle.css';
import { HashLink as Link } from 'react-router-hash-link';
import 'jarallax';
import Reveal from 'react-reveal/Reveal';

function VideoArticle() {
    
    return (
        
                
            <div className="films container-fluid">
<div className="jarallax" style={{height:"756px"}} data-jarallax data-jarallax-video="https://youtu.be/nHWcDMB2Cug?origin=https://youtu.be/nHWcDMB2Cug" ></div>
<script src="https://www.youtube.com/player_api"></script> 
        	<div className=" film-text" id="film-block">
					<h2>
						<span>
                        <Reveal efect="fadeInUp">
                        <div className="word">
                            <span data-blur="5" className="blur-5s">A</span>
                            <span data-blur="14" className="blur-14s">N</span>
                            <span data-blur="20" className="blur-20s">D</span>
                            <span data-blur="1" className="blur-1s">R</span>
                            <span data-blur="17" className="blur-17s">E</span>
                            <span data-blur="9" className="blur-9s">W</span>
                            </div>
                            </Reveal></span>
						
							</h2>
					<span className="subheader"><i>Documentary</i></span>
                                            <p>"Andrew" is a story on an athlete who cannot come to terms with his own weaknesses and the prospect of leaving the ring forever. Spectacular victories and defeats in the fights for the world heavyweight champion title come back to his mind like a mantra. The film shows his loneliness in the brutal world of the media and professional boxing. Only his wife Mariola, despite the dramatic experiences related to her husband’s sports career faithfully accompanies him in every important moment of his life. </p>
					<Link to="/productions#Andrew" className="btn btn-dark hvr-sweep-to-right"><i>Explore</i> </Link>
                   {/* 
                        <img src={iWord} className="all-word i-word" alt=""/>
                        <img src={fUpWord} className="all-word f-up-word" alt=""/>
                        <img src={fDownWord} className="all-word f-down-word" alt=""/>
                        */}
                </div>
                
            </div>
                
        
     
        
    )
}

export default VideoArticle
