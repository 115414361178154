import React from 'react';
import Card from '../components/Card';
import '../scss/Documentary.css';
import Navbar from '../components/Navbar';
import image from '../img/IFF-Logo-black-blue.png';
import image3 from '../img/main-photo.jpg';
import image1 from '../img/drone-photo.jpg';
import image2 from '../img/camera-photo.jpg';
import Carousel from 'react-bootstrap/esm/Carousel';

function Films() {
    return (
                <div className="main-cards">
                <div className="main-bar">
                 <div className="left-bar">
                <Navbar className="myNav"/>
                <ul className="social-media-card">
                        <li><a href="https://vimeo.com/user55473880">Vimeo</a></li>
                        <li><a href="https://www.youtube.com/channel/UCJJy5GJxh16VFu-Kix-2ZdQ">YouTube</a></li>
                        <li><a className="butt-about" target="_self" href="mailto:es@independentfilmfactory.com;rw@independentfilmfactory.com">EMAIL</a></li>
                    </ul>
                    </div>
                    
                 <img src={image} className="logo-header" style={{float:"right", marginRight: "4rem"}} alt=""/>
                    </div>
                <Card 
                title="Documentary production"
                    description="Documentary filmmaking is playing a huge role in our society as solving a problem starts
             from exposing a problem. And being a documentary production company means being responsible 
             for stories to tell. Communicating to the hearts and minds of a global audience, our documentaries
              impact the lives of the characters in the film as well as the individuals watching it. With that in mind, 
              we carefully consider every piece of a story and every aspect of a films’ production. We strongly believe that 
              a good documentary is a catalyst for action, and what that action will be is in the hands of filmmakers and
               film production companies. If you have a story to tell Inhale Films is the perfect companion to share it with the world."
               address="/Films"
                />
                <Carousel>
                    <Carousel.Item><img src={image} alt="" /></Carousel.Item>
                    <Carousel.Item><img src={image1} alt="" /></Carousel.Item>
                    <Carousel.Item><img src={image2} alt="" /></Carousel.Item>
                    <Carousel.Item><img src={image3} alt="" /></Carousel.Item>
                    

                </Carousel>
         
                    </div>
        
        
    )
}

export default Films
