import React from 'react';
import '../scss/Footer.css';
function Footer() {
    return (
        <div className="main-footer">
            <div className="row">
					<div className="col footer-content">
						<h2>
							<div className="word">
								<span className="blur-08s">W</span>
								<span className="blur-2s">E&nbsp;</span>
							</div>
							<div className="word">
								<span className="blur-12s">A</span>
								<span className="blur-09s">R</span>
								<span className="blur-14s">E&nbsp;</span>
							</div>
						
							<div className="word">
								<span className="blur-14s">L</span>
								<span className="blur-1s">O</span>
								<span className="blur-17s">O</span>
								<span className="blur-2s">K</span>
								<span className="blur-11s">i</span>
								<span className="blur-14s">n</span>
								<span className="blur-19s">g&nbsp;</span>
							</div>
						</h2>
						<h2>
							<div className="word">
								<span className="blur-15s">B</span>
								<span className="blur-12s">E</span>
								<span className="blur-15s">Y</span>
								<span className="blur-12s">O</span>
								<span className="blur-15s">N</span>
								<span className="blur-17s">D&nbsp;</span>
							</div>
							<div className="word">
								<span className="blur-14s">T</span>
								<span className="blur-08s">H</span>
								<span className="blur-13s">E&nbsp;</span>
							</div>
							<div className="word">
								<span className="blur-09s">N</span>
								<span className="blur-09s">E</span>
								<span className="blur-06s">W&nbsp;</span>
							</div>
							<div className="word">
								<span className="blur-14s">H</span>
								<span className="blur-11s">O</span>
								<span className="blur-17s">R</span>		
								<span className="blur-18s">I</span>
								<span className="blur-15s">Z</span>
								<span className="blur-21s">O</span>
								<span className="blur-13s">N!&nbsp;</span>
							</div>
						
						</h2>
						<div className="lower-text"><div><i>Copyright © 2020 Independent Film Factory </i></div>
						<div><i>designed by Malgorzata Wozniak</i></div></div>
					</div>
				</div>
            <ul className="social-media">
                <li><a href="https://vimeo.com/user55473880">Vimeo</a></li>
                <li><a href="//www.youtube.com/channel/UCJJy5GJxh16VFu-Kix-2ZdQ">YouTube</a></li>
                <li><a className="butt-about" target="_self" href="mailto:es@independentfilmfactory.com;rw@independentfilmfactory.com">EMAIL</a></li>
            </ul>
        </div>
    )
}

export default Footer
