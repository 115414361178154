import React from 'react'
import 'jarallax';
import Carousel from 'react-bootstrap/Carousel';
import '../scss/Video.css';

function Video() {
    return (
        <main className="main-video">
        <div className="full-background">
        
        <div className="jarallax" style={{height:"754px", display:"flex", justifyContent:"center"}} allow="autoplay;" 
         data-jarallax data-jarallax-video="https://youtu.be/EAMupQIXaIw?start=2?enablejsapi=1&origin=https://youtu.be/EAMupQIXaIw">
         <script src="https://www.youtube.com/player_api"></script> 
       <div style={{ textAlign: 'center', alignSelf: 'center' }}>
        <Carousel indicators={false} controls={false} slide={false} style={{marginLeft:"0",marginRight:"0"}}>
    <Carousel.Item>
    
			<h1 className="carousel-item active" style={{marginLeft:"15px"}}>
                <div className="word">
                    <span data-blur="3" className="blur-3s">F</span>
                    <span data-blur="17" className="blur-17s">i</span>
                    <span data-blur="4" className="blur-4s">l</span>
                    <span data-blur="9" className="blur-9s">m&nbsp;</span>
                    </div>
                <div className="word">
                <span data-blur="5" className="blur-5s">p</span>
                <span data-blur="7" className="blur-7s">r</span>
                <span data-blur="14" className="blur-14s">o</span>
                <span data-blur="2" className="blur-2s">d</span>
                <span data-blur="12" className="blur-12s">u</span>
                <span data-blur="6" className="blur-6s">c</span>
                <span data-blur="12" className="blur-12s">t</span>
                <span data-blur="4" className="blur-4s">i</span>
                <span data-blur="14" className="blur-14s">o</span>
                <span data-blur="19" className="blur-19s">n&nbsp;</span>
                </div></h1>
                </Carousel.Item>
                <Carousel.Item>
			<h1 className="carousel-item active">
            <div className="word">
                <span data-blur="4" className="blur-4s">D</span>
                <span data-blur="7" className="blur-7s">o</span>
                <span data-blur="5" className="blur-5s">c</span>
                <span data-blur="8" className="blur-8s">u</span>
                <span data-blur="18" className="blur-18s">m</span>
                <span data-blur="10" className="blur-10s">e</span>
                <span data-blur="8" className="blur-8s">n</span>
                <span data-blur="5" className="blur-5s">t</span>
                <span data-blur="1" className="blur-1s">a</span>
                <span data-blur="10" className="blur-10s">r</span>
                <span data-blur="2" className="blur-2s">y&nbsp;</span>
            </div>
            <div className="word">
                <span data-blur="11" className="blur-11s">p</span>
                <span data-blur="11" className="blur-11s">r</span>
                <span data-blur="20" className="blur-20s">o</span>
                <span data-blur="6" className="blur-6s">d</span>
                <span data-blur="12" className="blur-12s">u</span>
                <span data-blur="14" className="blur-14s">c</span>
                <span data-blur="18" className="blur-18s">t</span>
                <span data-blur="6" className="blur-6s">i</span>
                <span data-blur="5" className="blur-5s">o</span>
                <span data-blur="13" className="blur-13s">n&nbsp;</span>
                </div></h1>
                </Carousel.Item>
                <Carousel.Item>
			<h1 className="carousel-item active">
                <div className="word">
                    <span data-blur="19" className="blur-19s">T</span>
                    <span data-blur="8" className="blur-8s">V&nbsp;</span>
                    </div>
                <div className="word"><span data-blur="3" className="blur-3s">s</span>
                    <span data-blur="6" className="blur-6s">e</span>
                    <span data-blur="6" className="blur-6s">r</span>
                    <span data-blur="7" className="blur-7s">i</span>                    
                    <span data-blur="7" className="blur-7s">e</span>
                    <span data-blur="5" className="blur-5s">s&nbsp;</span>
                    </div>
                <div className="word">
                    <span data-blur="10" className="blur-10s">&&nbsp;</span></div>
                <div className="word">
                    <span data-blur="17" className="blur-17s">s</span>
                    <span data-blur="18" className="blur-18s">h</span>
                    <span data-blur="19" className="blur-19s">o</span>
                    <span data-blur="8" className="blur-8s">w</span>
                    <span data-blur="9" className="blur-9s">s&nbsp;</span>
                    </div></h1>
                    </Carousel.Item>
		</Carousel>
        </div>
        </div>
          
	
	</div>
        </main>
    )
}

export default Video
