import React from 'react';
import Card from '../components/Card';
import '../scss/Documentary.css';
import Navbar from '../components/Navbar';
import image from '../img/IFF-Logo-black-blue.png';
import image3 from '../img/main-photo.jpg';
import image1 from '../img/drone-photo.jpg';
import image2 from '../img/camera-photo.jpg';

function FilmsProductions() {
    return (
        <div className="main-cards">
        <div className="main-bar">
         <div className="left-bar">
        <Navbar className="myNav"/>
        <ul className="social-media-card">
                <li><a href="https://vimeo.com/user55473880">Vimeo</a></li>
                <li><a href="//www.youtube.com/channel/UCJJy5GJxh16VFu-Kix-2ZdQ">YouTube</a></li>
                <li><a className="butt-about" target="_self" href="mailto:es@independentfilmfactory.com;rw@independentfilmfactory.com">EMAIL</a></li>
            </ul>
            </div>
            
         <img src={image} className="logo-header" style={{float:"right", marginRight: "4rem"}} alt=""/>
            </div>
        <Card image1={image1}
        image2={image2}
        image3={image3}
        title="Films productions"
            description=""
       address="/productions"
        />
        <script src="https://www.youtube.com/player_api"></script> 
        </div>
    )
}

export default FilmsProductions
