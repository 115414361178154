import React from 'react';
import '../scss/Article.css';
import '../scss/About.css';
import ScrollOut from 'scroll-out';
import image from '../img/IMG_1409_resized.jpg';
import image1 from '../img/IMG_3846_resized.jpg';
import Navbar from './Navbar';

ScrollOut({
    targets: ".page"
  });
  
  
function About() {      
    return (
        <div className="all-label">
        <div  style={{display: 'grid',
    float: 'left'}}>
     <div className="left-bar">
        <Navbar className="myNav"/>
        <ul className="social-media-card">
                <li><a href="https://vimeo.com/user55473880">Vimeo</a></li>
                <li><a href="//www.youtube.com/channel/UCJJy5GJxh16VFu-Kix-2ZdQ">YouTube</a></li>
                <li><a className="butt-about" target="_self" href="mailto:es@independentfilmfactory.com;rw@independentfilmfactory.com">EMAIL</a></li>
            </ul>
            </div>
            
            
        </div>


<div className="container-1">
    <div className="page" id="about-us">
        <div className="text text-about"><div className="label">

<h2><div className="word">
<span data-blur="17" className="blur-17s art">A</span>
<span data-blur="18" className="blur-18s art">B</span>
<span data-blur="13" className="blur-13s art">O</span>
<span data-blur="4" className="blur-4s art">U</span>
<span data-blur="14" className="blur-14s art">T&nbsp;</span>
<span data-blur="13" className="blur-13s art">U</span>
<span data-blur="3" className="blur-3s art">S&nbsp;</span>
</div> </h2>
    <p>
    Independent Film Factory (IFF) evolved from Chicago Film Factory in 2013.
We are a Polish-American production company producing TV shows, series,
documentaries and feature films.
    </p>
   
        <h3>For individual and corporate customers, IFF offers:</h3>
        <ul className="list-offer-main">
            <li className="list-offer">Commercial Productions</li>
            <li>Educational Productions</li>
            <li  className="list-offer">Full Cycle Productions & Pre-production services</li>
            <li>Location Scouting</li>
            <li  className="list-offer">Casting Services</li>
        </ul>

</div>
<img className="img-about"  src={image} alt=""/>

</div>
    </div>
    <div className="page" id="crew">
        <div className="text text-about" data-scroll="out" data-splitting=""><div className="label">

<h2><div className="word">
<span data-blur="17" className="blur-17s art">C</span>
<span data-blur="18" className="blur-18s art">R</span>
<span data-blur="13" className="blur-13s art">E</span>
<span data-blur="14" className="blur-14s art">W&nbsp;</span>
</div> </h2>
<p>
IFF was established by veterans of film, advertising, and marketing industries,
as well as print and broadcast media.

We are a group of experienced and creative people – enthusiasts and experts
in the field of film and film production.
</p>


</div>
<img className="img-about" src={image1} alt=""/></div>
    </div>



   
</div>
        </div>
    )
}

export default About
