import React from 'react';
import '../scss/CardProduct.css';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import Slide from 'react-reveal/Slide';

function CardProduct( {image, title, description, link} ) {
    return (
        <div className="video_card">
       <Slide right> <img src={image} alt="" /></Slide>
        <h2>{title}</h2>
              <p className="videoCard__stats">{description}</p>
              <div className="container-product">
  <ul>
    <li>
    <Slide right> <NavLink className="animated-arrow" to={link}>
        <span className="the-arrow -left">
          <span className="shaft"></span>
        </span>
        <span className="main">
          <span className="text">
            Click for more
          </span>
          <span className="the-arrow -right">
            <span className="shaft"></span>
          </span>
        </span>
      </NavLink></Slide>
    </li>
  </ul>
</div>
        </div>
    )
}

export default CardProduct
