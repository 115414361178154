import React from 'react';
import Card from '../components/Card';
import '../scss/Documentary.css';
import '../scss/About.css';
import Navbar from '../components/Navbar';
import image from '../img/IFF-Logo-black-blue.png';
import image1 from '../img/ENDRJU Diploma of Awards (1).jpeg';
import image3 from '../img/winner.png';
import image2 from '../img/krakow-film-festiwal.png';
import FlipMove from "react-flip-move";

function Documentary() {
    return (
        <div className="main-cards" style={{ height:"100%", overflow:"scroll"}}>
        <div className="main-bar">
         <div className="left-bar">
        <Navbar className="myNav"/>
        <ul className="social-media-card">
                <li><a href="https://vimeo.com/user55473880">Vimeo</a></li>
                <li><a href="https://www.youtube.com/channel/UCJJy5GJxh16VFu-Kix-2ZdQ">YouTube</a></li>
                <li><a className="butt-about" target="_self" href="mailto:es@independentfilmfactory.com;rw@independentfilmfactory.com">EMAIL</a></li>
            </ul>
            </div>
            
         <img src={image} className="logo-header logo-header-card" style={{float:"right", marginRight: "2rem"}} alt=""/>
            </div>
        <Card className="card_doc" image1={image1}
        image2={image2}
        image3={image3}
        title="Documentary & film production"
            description="Independent Film Factory deals with film and television production. Our achievements include: documentary film about Andrzej Gołota entitled - Endrju, which received a significant award at the Polish Film Festival in America in November 2014, and the prestigious Silver Hugo Award at the American Television Film Festival in Chicago in 2015. In 2016, a documentary entitled -It all started in Greenpoint."
       address="/productions"
        />
        <div className="image_mobile_container_main">
                <div className="image_mobile_container">
                <FlipMove>
        <img className="image_mobile" src={image1} alt=""/>
        </FlipMove>
        <FlipMove>
        <img className="image_mobile" src={image2} alt=""/>
        </FlipMove>
        <FlipMove>
        <img className="image_mobile" src={image3} alt=""/>
        </FlipMove>
        </div>
        </div>
			</div>
	
    )
}

export default Documentary
