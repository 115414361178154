import React from 'react';
import '../scss/Navbar.css';

import { NavHashLink as NavLink } from 'react-router-hash-link';

class MyComponent extends React.Component {
  
    state = {
        active: false,
      }
  
  
    render() {
      return (   
          
      <section  id="myNav" >
	<div className="hamburger-all"  onClick={() => this.setState({active: !this.state.active})}>
    <div className="hamburger-box">
    <span className={ this.state.active ? "hamburger on" : "hamburger"}  ></span>
   
    </div>
     


<div className={this.state.active ? "overlay open" : "overlay"}>
	<div className="row overlay-content">
    
		<ul data-router-wrapper id="menu-1" ><li><NavLink to="/">Home</NavLink></li>
        <li><NavLink to='/about#about-us'>What we do</NavLink>
            <ul data-router-wrapper>
                <li><NavLink to="/about#crew">Our crew</NavLink></li>
                <li><NavLink  to="/documentary">Documentary & film production</NavLink></li>
                <li><NavLink to="/tv-series-and-shows">Tv series & shows</NavLink></li>
            </ul>
        </li>
            <li data-router-wrapper><NavLink to="/productions/">Productions</NavLink>
                <ul>
                
                    <li><NavLink to="/productions#Absolution">Absolution</NavLink></li>
                    <li><NavLink to="/productions#It-all-started-in-Greenpoint" >It All Started in Greenpoint</NavLink></li>
                    <li><NavLink to="/productions#Falcons-of-freedom">FALCONS OF FREEDOM</NavLink></li>
                    <li><NavLink to="/productions#Andrew">ANDREW</NavLink></li>                   
                    <li><NavLink to="/productions#Flavor-of-Poland-season-1">Flavor of Poland - season 1</NavLink></li>
                  
                    
                    </ul></li>
                    <li><NavLink to="/in-production"  className="in-production">In Production</NavLink>
                    <ul data-router-wrapper>
                    <li><a href="https://www.flavorofpoland.com/">Flavor of Poland - season 2</a></li>
                    <li><NavLink exact to="/in-production#from-the-sky">From the sky</NavLink></li>
                    </ul>
                    </li>
                    <li><a target="_self" href="mailto:es@independentfilmfactory.com;rw@independentfilmfactory.com">Contact</a></li>
                    </ul>
	</div>
    </div>
    
    </div>
</section>
      )
    }
  }



function Navbar() {


    
    return (
        <div>
                <MyComponent/>
       
       </div>
    )
}

export default Navbar
