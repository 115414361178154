import React from 'react';
import Video from '../components/Video';
import Article from '../components/Article';
import Footer from '../components/Footer';
import Header from '../components/Header';
import VideoArticle from '../components/VideoArticle';
import { Container, Row } from 'reactstrap';
import '../scss/Home.css';
import Reveal from 'react-reveal/Reveal';



  
function Home() {
    return (
        <div className="container_main">
    
            <Container fluid>
    <Row>
      <Header />
      </Row>
      <Row>
      <Video/>
      </Row>
    
      <Row>
      <Article/>
      </Row>
      <Row>
      <VideoArticle/>
      </Row>
  
      <Row>
      <Footer/>
      </Row>
     
      </Container>
      <script src="https://www.youtube.com/player_api"></script> 
        </div>
    )
}

export default Home



