import React from 'react';
import '../scss/Card.css';
import '../scss/Article.css';
import Flickity from 'react-flickity-component';
import '../scss/flickity.css';

import {Link} from 'react-router-dom';



function Card({image3, image1, image2, title, description, address}) {
    return (
        <div  className="main__card">
 <Flickity style={{marginLeft:"10%"}}
      className={'carousel'} // default ''
      elementType={'div'} // default 'div'
      disableImagesLoaded={false} // default false
      reloadOnUpdate // default false
      static // default false
    >
      <img  style={{
    height:'100vh', position: 'none'}} src={image3} alt=""/>
      <img  style={{
    height:'100vh', position: 'none' }} src={image1} alt=""/>
      <img style={{
    height:'100vh',  position: 'none'}} src={image2} alt=""/>
    </Flickity>
        <div className="card">
            <div className="word card__info">
                <h2  data-blur="14" className="blur-14s art">{title}</h2>
                <h4>{description}</h4>
                <Link className="btn btn-dark hvr-sweep-to-right  btn-card" to={address}><i>More</i> <i className="arrow right"></i></Link>
            </div>
        </div>
        </div>
    )
}

export default Card
