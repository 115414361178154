import React from 'react';
import './scss/App.css';
import Home from './pages/Home';
import Productions from './pages/Productions';
import InProduction from './pages/InProduction';
import Contact from './pages/Contact';
import Documentary from './pages/Documentary';
import FilmsProductions from './pages/FilmsProductions';
import About from './components/About';
import TvSeriesShows from './pages/TvSeriesShows';
import Films from './pages/Films';
import './components/Shift';
import { PageTransition } from '@steveeeie/react-page-transition';

import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';

function App() {
  return (
    <div className="App" style={{height:"inherit"}}>
    <BrowserRouter>
     <Route
        render={({ location }) => {
          return (
          
 
      
      
        <PageTransition
              preset="cubeToLeft"
              transitionKey={location.pathname}
            >
            
            <Switch>
            <Route exact path="/"  component={Home}/>
        <Route exact path="/productions" component={Productions}/>
        <Route exact path="/in-production"   component={InProduction}/>
        <Route exact path="/contact"   component={Contact}/>
        <Route exact path="/documentary"   component={Documentary}/>
        <Route exact path="/films-productions"   component={FilmsProductions}/>
        <Route exact path="/tv-series-and-shows"   component={TvSeriesShows}/>
        <Route exact path="/about"   component={About}/>
        <Route exact path="/films"   component={Films}/>
        <Redirect to="/"/>
        </Switch>
    </PageTransition>
  
          );
        }}
      />
    </BrowserRouter>
    </div>
  
  );
}

export default App;
