import React from 'react';
import logophoto from '../img/IFF-Logo-black-white-violet.png';
import '../scss/Header.css';
import Navbar from './Navbar';
import {Link} from 'react-router-dom';

function Header() {
    return (
        <div >
        <div className="main-header">
        <h4 style={{marginLeft:"20px"}}className="left-header left-h1"><Navbar/></h4>
         
            <img src={logophoto} className="logo-header" alt="logo"/>
            
            <ul className="left-header-main">
            <h4 className="left-header left-h1"><Link to="/productions">Productions</Link></h4>
            <h4 className="left-header left-h2"><Link to="/in-production">In production</Link></h4>
            <a className="left-header " target="_self" href="mailto:es@independentfilmfactory.com;rw@independentfilmfactory.com"> <h4 className="btn btn-outline btn-navbar left-header left-h3">Contact</h4></a>
            </ul>
            </div>
        </div>
    )
}

export default Header
