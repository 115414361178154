import React from 'react';
import '../scss/Article.css';
import '../scss/About.css';
import ScrollOut from 'scroll-out';
import Navbar from '../components/Navbar';
import videoEuropeSky from '../movie/From The Sky Demo.mp4?start=2';
import ResponsiveVideo from '../components/ResponsiveVideo';
import skyImage from '../img/sky.png';
import Reveal from 'react-reveal/Reveal';
ScrollOut({
    targets: ".page"
  });

  class ButtonHideShow extends React.Component{
    constructor(){
        super();
        this.state={
            show:true
        }
    }
    render(){
      return (
     
                <div className="button button-outline" onClick={()=>{this.setState({show:!this.state.show})}}>
               { this.state.show? 'See more' :  <Reveal efect="fadeInUp">'Europe from the Sky is an exciting new series for American Public Television, offering viewers a distinctive bird’s eye view of various countries in Europe. 60-minute episodes will take viewers on a breathtaking aerial tour around each European country. The series will present richness of culture and history, beauty of landscape of forests, rivers, mountain ranges and beaches, many historical and heritage sites, and modern cities as well as. Europe from the Sky will provide an unusual perspective, through the eyes of a drone, bringing viewers on a special journey they would not otherwise experience from the ground.'</Reveal>}   </div>
         
      );
    }
  }
  
function InProductions() {    
  
  return (
      <div className="all-label">
      <div  style={{display: 'grid',
  float: 'left'}}>
   <div className="left-bar">
      <Navbar className="myNav"/>
      <ul className="social-media-card">
              <li><a href="https://vimeo.com/user55473880">Vimeo</a></li>
              <li><a href="https://www.youtube.com/channel/UCJJy5GJxh16VFu-Kix-2ZdQ">YouTube</a></li>
              <li><a className="butt-about" target="_self" href="mailto:es@independentfilmfactory.com;rw@independentfilmfactory.com">EMAIL</a></li>
          </ul>
          </div>
          
      </div>


<div className="container-1">
 
  <div className="page">
  
<ResponsiveVideo url={videoEuropeSky}
config={{
    file: { 
      attributes: { 
        poster: {skyImage}
      } 
    } 
  }}
/>
      <div className="text text--random" data-scroll="out" data-splitting=""><div className="label">

<h2><div className="word">
<span data-blur="17" className="blur-17s art">EUROPE&nbsp;</span>
<span data-blur="14" className="blur-14s art">FROM&nbsp;</span>
<span data-blur="13" className="blur-13s art">THE&nbsp;</span>
<span data-blur="13" className="blur-13s art">SKY&nbsp;</span>

</div> </h2>


<h6 className="inner__word">(TV series, 2020/2021)</h6>

<p>

</p>
<ButtonHideShow />

</div>

  </div>

</div>

 
</div>
      </div>
  )
}


export default InProductions
