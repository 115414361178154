import React, { useState } from 'react';
import '../scss/Article.css';
import '../scss/About.css';
import ScrollOut from 'scroll-out';
import Navbar from '../components/Navbar';
import 'jarallax';
import ResponsivePlayer from '../components/ResponsiveVideo';
import CardProduct from '../components/CardProduct';
import image1 from '../img/flavor-of-poland.png';
import image2 from '../img/falcons-of-freedom.png';
import image3 from '../img/Andrew.png';
import image4 from '../img/It-all-started-in-Greenpoint.png';
import image5 from '../img/Absolution.png';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

ScrollOut({
    targets: ".page"
  });


function Productions() {   
  const [watchComplete, setWatchComplete] = useState(false)

  const handleWatchComplete = ({ played }) => {
    if (played >= 0.7 && !watchComplete) {
      setWatchComplete(true)
    }
  }   
  return (
      <div className="all-label">
      <div  style={{display: 'grid',
  float: 'left'}}>
   <div className="left-bar">
      <Navbar className="myNav"/>
      <ul className="social-media-card">
              <li><a href="https://vimeo.com/user55473880">Vimeo</a></li>
              <li><a href="https://www.youtube.com/channel/UCJJy5GJxh16VFu-Kix-2ZdQ">YouTube</a></li>
              <li><a className="butt-about" target="_self" href="mailto:es@independentfilmfactory.com;rw@independentfilmfactory.com">EMAIL</a></li>
          </ul>
          </div>
          
          
      </div>


<div className="container-1">

<div id="productions" className="page">
<h1 className="main-title">Check Productions    <ArrowForwardIosIcon className="arrow-title"/> </h1>


<div className="card_product">

    <CardProduct 
     image={image1}
      title="Flavor of Poland - season 1"
      description="Flavor of Poland is the first show in American TV history that features 13 episodes dedicated to Poland and its culinary heritage."
      link="/productions#Flavor-of-Poland-season-1"

    />
     <CardProduct 
     image={image2}
      title="Falcons of Freedom"
      description="Falcons of Freedom is a unique documentary film that uncovers the pivotal role of the U.S., Canada and the Polish-American community in the fight for Poland's Independence. "
      link="/productions#Falcons-of-freedom"
    />
    <CardProduct 
     image={image3}
      title="Andrew"
      description="Andrew (Endrju) is a story on an athlete who cannot come to terms with his own weaknesses and the prospect of leaving the ring forever."
      link="/productions#Andrew"
    />
     <CardProduct 
      image={image4}
      title="It all started in Greenpoint"
      description="A documentary about history of Polish & Slavic Federal Credit Union."
      link="/productions#It-all-started-in-Greenpoint"
    />
    <CardProduct 
      image={image5}
      title="Absolution"
      description="In a world of violent coincidences there are still no rules and choices revolving around the question of human morality."
      link="/productions#Absolution"

    />

    </div>
</div>
  <div id="Flavor-of-Poland-season-1" className="page">
    
<ResponsivePlayer
        url="https://player.vimeo.com/video/380290531?title=0&byline=0&portrait=0"
        onProgress={handleWatchComplete}
      />
      <script src="https://www.youtube.com/player_api"></script> 
      <div className="text text--random" data-scroll="out" data-splitting=""><div className="label">

<h2><div className="word">
<span data-blur="17" className="blur-17s article article-1">FLAVOR&nbsp;</span>
<span data-blur="13" className="blur-13s article  article-1">OF&nbsp;</span>
<span data-blur="13" className="blur-13s article  article-1">POLAND</span>

</div> </h2>
<h6 className="inner__word">(TV series, 2019/2020)</h6>
<p>
<b>Flavor of Poland </b> is the first show in American TV history that features 13 episodes dedicated to Poland and its culinary heritage. The charming cooking and travel series that just completed a very successful first season takes viewers on a romantic and adventurous journey across Poland's colorful regions. 
The first season premiered in January 2020 on American Public Television stations across the United States. In its premiere run, the show demonstrated great interest and success with the American audience and was picked up by 306 local stations in 44 states - an exceptional starticle for a brand new television series. The series is also available via APT's national Create channel which has an 87% coverage that reaches millions of viewers across the U.S.
</p>



</div>

  </div>

</div>

 




<div id="Falcons-of-freedom" className="page">
      <div className="text text--random" data-scroll="out" data-splitting="">
      <ResponsivePlayer className="react-player"
        url="https://www.youtube.com/watch?v=ePrHbIe-IK4?looped=1"
        onProgress={handleWatchComplete}
      />
      <script src="https://www.youtube.com/player_api"></script> 
      <div className="label">

<h2><div className="word">
<span data-blur="17" className="blur-17s article  article-1">FALCONS&nbsp;</span>
<span data-blur="13" className="blur-13s article  article-1">OF&nbsp;</span>
<span data-blur="13" className="blur-13s article  article-1">FREEDOM</span>

</div> </h2>

<h6 className="inner__word">(Documentary, coproduction, 2017)</h6>

<p>
<b>Falcons of Freedom</b> is a unique documentary film that uncovers the pivotal role of the U.S., Canada and the Polish-American community in the fight for Poland's Independence. The story is told through the perspective of a young Polish-American who embarks on a journey to discover his polish heritage. This is a truly universal film, which speaks not only about the importance of the fight for freedom but also about the significance of who one is and where one is from. The film also showcases archival footage that has never been publicly released before!</p>



</div>
  </div>

</div>
<div id="Andrew" className="page">
      <div className="text">
      <ResponsivePlayer className="react-player"
        url="https://www.youtube.com/watch?v=nHWcDMB2Cug"
        onProgress={handleWatchComplete}
      />
      <script src="https://www.youtube.com/player_api"></script> 
      <div className="label">

      <h2><div className="word">
<span data-blur="15" className="blur-17s article">A</span>
<span data-blur="17" className="blur-18s article">N</span>
<span data-blur="11" className="blur-13s article">D</span>
<span data-blur="2" className="blur-4s article">R</span>
<span data-blur="18" className="blur-14s article">E</span>
<span data-blur="2" className="blur-3s article">W&nbsp;</span>
</div> </h2>


<h6 className="inner__word">(Documentary, coproduction, 2014)</h6>

  <p>
  <b>Andrew (Endrju)</b> is a story on an athlete who cannot come to terms with his own weaknesses and the prospect of leaving the ring forever. Spectacular victories and defeats in the fights for the world heavyweight champion title come back to his mind like a mantra. The film shows his loneliness in the brutal world of the media and professional boxing. Only his wife Mariola, despite the dramatic experiences related to her husband’s sports career faithfully accompanies him in every important moment of his life. 
  </p>
</div>
</div>
  </div>


  <div id="It-all-started-in-Greenpoint" className="page">
      <div className="text">
      <ResponsivePlayer className="react-player-yt"
        url="https://www.youtube.com/embed/tFqvGK-yidY"
        onProgress={handleWatchComplete}
      />
      <script src="https://www.youtube.com/player_api"></script> 
      <div className="label">


<h2><div className="word">
<span data-blur="15" className="blur-17s article  article-1">IT&nbsp;</span>
<span data-blur="11" className="blur-13s article  article-1">ALL&nbsp;</span>
<span data-blur="15" className="blur-17s article  article-1">STARTED&nbsp;</span>
<span data-blur="9" className="blur-4s article  article-1">IN&nbsp;</span>
<span data-blur="15" className="blur-17s article  article-1">GREENPOINT&nbsp;</span>
</div> </h2>


<h6 className="inner__word">(Corporate documentary, 2015)</h6>

  <p>
  A documentary about history of Polish & Slavic Federal Credit Union established by Polish immigrants in New York's Greenpoint in 1976.
</p></div>
</div>
  </div>
 

  <div id="Absolution" className="page">
  
      <div className="text">
      <ResponsivePlayer className="react-player-yt"
        url="https://www.youtube.com/embed/EAMupQIXaIw?starticle=2"
        onProgress={handleWatchComplete}
      />
<script src="https://www.youtube.com/player_api"></script> 
      <div className="label">


<h2><div className="word">
<span data-blur="15" className="blur-17s article">A</span>
<span data-blur="17" className="blur-18s article">B</span>
<span data-blur="11" className="blur-13s article">S</span>
<span data-blur="2" className="blur-4s article">O</span>
<span data-blur="18" className="blur-14s article">L</span>
<span data-blur="15" className="blur-17s article">U</span>
<span data-blur="17" className="blur-18s article">T</span>
<span data-blur="11" className="blur-13s article">I</span>
<span data-blur="2" className="blur-4s article">O</span>
<span data-blur="2" className="blur-3s article">N&nbsp;</span>
</div> </h2>


<h6 className="inner__word">(Short feature, 2010)</h6>

  <p>
 
In a world of violent coincidences there are still no rules and choices revolving around the question of human morality.
A homeless man witnesses a body being dropped by two undercover police officers outside his hovel. After they leave, he decides to go closer to the body. He recognizes that the young woman is actually still alive and decides to help her. The effort of putting her into a shopping carticle and taking her home sparks a change in him. At her house, he discovers that there is much more to their meeting under unfortunate circumstances than coincidence. The whole situation creates an opportunity for both of them to change their current paths. They receive a rare shot at ABSOLUTION. </p>
</div>
 

</div>
  </div>
 




</div>
      </div>
  )
}


export default Productions
