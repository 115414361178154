import React from 'react';
import Navbar from '../components/Navbar';

function Contact() {
    return (
        <div>
            <Navbar/>
          
        </div>
    )
}

export default Contact
