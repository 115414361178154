import React from 'react';
import '../scss/Article.css';
import '../scss/VideoArticle.css';
import {Link} from 'react-router-dom';
function Article() {
    return (
        <div className="main-article">
           <h2>
           <span data-waypoint="#about-block" data-done="1">
        <div className="word">
           <span data-blur="10" className="blur-10s article-home">A</span>
           <span data-blur="2" className="blur-2s article-home">B</span>
           <span data-blur="14" className="blur-14s article-home">O</span>
           <span data-blur="1" className="blur-1s article-home">U</span>
           <span data-blur="7" className="blur-7s article-home">T&nbsp;</span>
        </div></span></h2>
           <span><i>WHAT WE DO</i> </span>
           <p>Independent Film Factory (IFF) evolved from Chicago Film Factory in 2013.

We are a Polish-American production company producing TV shows, series,
documentaries and feature films.
For individual and corporate customers, IFF offers: Commercial Productions, Educational Productions, Full Cycle Productions & Pre-production services, Location Scouting, Casting Services, Equipment Rental

IFF was established by veterans of film, advertising, and marketing industries, 
as well as print and broadcast media.

We are a group of experienced and creative people – enthusiasts and experts in the field of film and film production.

  </p>
        <Link to="/about" className="btn btn-dark hvr-sweep-to-right"><i>More</i> <i className="arrow right"></i></Link>
        </div>
    )
}

export default Article
